/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import voteApi from './api';
import { ChoixReponse } from './slice';

export const sendVotes = createAsyncThunk(
  `vote/sendVotes`,
  async (parameters: {
    token: string;
    scrutinUniqueId: string;
    votes: ChoixReponse;
  }) => {
    const choix = Object.keys(parameters.votes).map((k) => {
      const questionId = Number.parseInt(k, 10);
      return {
        questionId,
        reponsesId: parameters.votes[questionId].filter((id) => id > 0), // élimine les abstentions
      };
    });

    const response = await voteApi.sendVotes(
      parameters.token,
      parameters.scrutinUniqueId,
      choix
    );
    return response;
  }
);
