/* eslint-disable unicorn/prevent-abbreviations */
import 'numeral/locales/fr';

import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import moment from 'moment';
import numeral from 'numeral';
import { initReactI18next } from 'react-i18next';
import 'moment/locale/fr';

import langueEn from './locales/en';
import langueFr from './locales/fr';

export const defaultLocale = 'fr';
moment.locale(defaultLocale);
numeral.locale(defaultLocale);

const resources = {
  en: langueEn,
  fr: langueFr,
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next

  .init({
    resources,
    lng: defaultLocale,
    fallbackLng: defaultLocale,

    keySeparator: false,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

i18n.on('languageChanged', (lng) => {
  moment.locale(lng);
  numeral.locale(lng);
});

export default i18n;
