/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import ThemeApi from './api';
import { themeSchema } from './schemas';

export const fetchAllThemes = createAsyncThunk(
  `themes/fetchAllThemes`,
  async (token: string) => {
    const response = await ThemeApi.fetchAllTheme(token);
    return normalize(response, [themeSchema]);
  }
);

export const fetchThemeById = createAsyncThunk(
  `themes/fetchThemeById`,
  async (parameters: { id: number; token: string }) => {
    const response = await ThemeApi.fetchThemeById(parameters.id, parameters.token);
    return normalize(response, themeSchema);
  }
);
