import { makeStyles, Paper, Typography } from '@material-ui/core'
import { FunctionComponent, Suspense } from 'react'


const useStyles = makeStyles((theme) => ({
    loadingPaper: {
        margin: 'auto',
        marginTop: theme.spacing(4),
        padding: theme.spacing(2),
        width: '80vw',
        height: '40vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'

    }
}));

const LoadingMessage = () => {
    const classes = useStyles();

    return (
        <Paper className={classes.loadingPaper}>
            <Typography variant='h2'>Votez.com</Typography>
            <Typography variant='h3' >Un instant svp...</Typography>
        </Paper>
    )
}

const SuspenseMessage: FunctionComponent = ({ children }) => (
    <Suspense fallback={<LoadingMessage />}>
        {children}
    </Suspense>
)

export default SuspenseMessage
