import { ThemeCampagne } from 'models/adminModel';
import { schema } from 'normalizr';

// Définitions pour Normalizr

export const themeSchema = new schema.Entity<ThemeCampagne>(
  'Themes',
  {},
  {
    idAttribute: 'Id',
  }
);

export type ThemeSliceEntities = {
  Themes: {
    [themeId: number]: ThemeCampagne;
  };
};
