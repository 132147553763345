/* Semble avoir un bug avec typescript */
/* eslint-disable no-shadow */
export enum ScrutinErrorCodes {
  TOKEN_INVALIDE = 1,
  SCRUTIN_EARLY = 2,
  SCRUTIN_LATE = 3,
}

export enum EtatScrutin {
  PREPARATION = 1,
  ACTIF = 2,
  TERMINE = 3,
  ENATTENTEDEVOTE = 4,
  ADPOTEE = 5,
  REJETTEE = 6,
  NONTRAITE = 7,
}

export enum EtatPresence {
  INCONNU = 1,
  PRESENT = 2,
  ABSENT = 3,
}

export enum EtatInscription {
  CONFIRMEE = 1,
  EN_ATTENTE = 2,
  ANNULEE = 3,
}
