/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Candidat } from 'models/voteModel';
import { normalize, schema } from 'normalizr';
import api, { EditCandidatParameters } from './api';

// TODO: Generalize
const candidatSchema = new schema.Entity<Candidat>(
  'Candidats',
  {},
  {
    idAttribute: 'Id',
  }
);

export const fetchAllCandidats = createAsyncThunk(
  `admin/candidats/fetchAllCandidats`,
  async (parameters: { token: string; campagneId: number }) => {
    const response = await api.fetchAllCandidats(parameters);
    return normalize(response, [candidatSchema]);
  }
);

export const editCandidat = createAsyncThunk(
  `admin/candidats/editCandidat`,
  async (parameters: {
    token: string;
    campagneId: number;
    candidat: EditCandidatParameters;
  }) => api.editCandidat(parameters)
);
