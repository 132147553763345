// On réassigne state mais c'est prévu par redux-toolkit
/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initierVote } from 'features/vote/slice';
import _ from 'lodash';
import { EtatPresence, EtatScrutin } from 'models/enums';
import { Bulletin, Campagne, ResultatsDirectScrutin } from 'models/voteModel';
import { AppState } from 'store';
import { extractDataFromToken } from 'utils';
import { fetchCampagne } from './actions';

const sliceName = 'home';

export type TypeCampagne = 'EnDirect' | 'Courriel';

export interface HomeSlice {
  token: string | null;
  Campagne?: Campagne;
  Bulletin?: Bulletin;
  scrutinsDansToken: string[];
}

const initialState: HomeSlice = {
  token: null,
  scrutinsDansToken: [],
};

export const homeSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | null>) => {
      let scrutinsDansToken: string[] = [];

      if (action.payload) {
        const content = extractDataFromToken(action.payload);
        scrutinsDansToken = content.scrutins || [];
      }

      state.scrutinsDansToken = scrutinsDansToken;
      state.token = action.payload;
    },
    updateResultat: (state, action: PayloadAction<ResultatsDirectScrutin>) => {
      if (state.Campagne) {
        const index = state.Campagne.Scrutins.findIndex(
          (scrutin) => scrutin.Id === action.payload.Id
        );
        if (index > -1) {
          state.Campagne.Scrutins[index] = action.payload;
        } else {
          state.Campagne.Scrutins.push(action.payload);
        }
      }

      return state;
    },
    updateDroitDeVote: (state, action: PayloadAction<boolean>) => {
      if (state.Bulletin?.Inscription) {
        state.Bulletin.Inscription.DroitDeVote = action.payload;
      }
      return state;
    },
    modificationCampagne: (
      state,
      action: PayloadAction<{ Campagne: Campagne }>
    ) => {
      _.merge(state, action.payload);
      return state;
    },
    priseDesPresences: (state) => {
      if (state.Bulletin && state.Bulletin.Inscription) {
        state.Bulletin.Inscription.EtatPresenceRefId = EtatPresence.INCONNU;
      }
      return state;
    },
    reponsePrisePresence: (state) => {
      if (state.Bulletin && state.Bulletin.Inscription) {
        // Si la réponse au challenge de la prise des présence n'est pas bonne, il sera
        // absent.  On ne l'indique pas coté client pour des raison évidentes :)
        state.Bulletin.Inscription.EtatPresenceRefId = EtatPresence.PRESENT;
      }
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCampagne.fulfilled, (state, action) => ({
      ...state,
      Campagne: action.payload.Campagne,
      Bulletin: action.payload.Bulletin,
    }));

    builder.addCase(initierVote, (state, action) => {
      const scrutin = state.Campagne?.Scrutins.find(
        (s) => s.Id === action.payload.scrutin.Id
      );
      if (scrutin) {
        scrutin.EtatRefId = EtatScrutin.ACTIF;
      }
      return state;
    });
  },
});

export const {
  setToken,
  updateResultat,
  updateDroitDeVote,
  modificationCampagne,
  priseDesPresences,
  reponsePrisePresence,
} = homeSlice.actions;

export const homeSliceName = homeSlice.name;

export const selectToken = (state: AppState) => state.home.token;

export const selectTypeCampagne: (state: AppState) => TypeCampagne | null = (
  state
) => {
  if (!state.home.Campagne) return null;
  return state.home.Campagne?.EstDirect ? 'EnDirect' : 'Courriel';
};

export const selectScrutinsDansToken = (state: AppState) =>
  state.home.scrutinsDansToken;

export const selectCampagne = (state: AppState) => state.home.Campagne;

export const selectScrutins = (state: AppState) =>
  state.home.Campagne?.Scrutins || [];

export const selectInscription = (state: AppState) =>
  state.home.Bulletin?.Inscription;

export default homeSlice.reducer;
