import { AxiosRequestConfig } from 'axios';
import { PathLike } from 'fs';
import qs from 'qs';

const baseApiConfig: AxiosRequestConfig = {
  withCredentials: false,
  timeout: 120000,
  baseURL: process.env.REACT_APP_VOTEZ_API_URL,
  headers: {
    common: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  },
  paramsSerializer: (parameters: PathLike) =>
    qs.stringify(parameters, { indices: false }),
};

const apiConfig: AxiosRequestConfig = { ...baseApiConfig };
export default apiConfig;
