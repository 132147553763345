// On réassigne state mais c'est prévu par redux-toolkit
/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { createSlice } from '@reduxjs/toolkit';
import { CampagnePourInscription, Inscription } from 'models/voteModel';
import { AppState } from 'store';
import { addInscription, fetchCampagne } from './actions';

const sliceName = 'support';

// export type SoutienState = 'Accueil' | 'En attente approbation';

export interface SoutienSlice {
  campagne?: CampagnePourInscription;
  inscription?: Inscription;
}

const initialState: SoutienSlice = {
  // inscriptionState: 'Inscription',
};

export const soutienSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCampagne.fulfilled, (state, action) => ({
      ...state,
      campagne: action.payload.Campagne,
    }));
    builder.addCase(addInscription.fulfilled, (state, action) => ({
      ...state,
      inscription: action.payload,
    }));
  },
});

// export const {} = soutienSlice.actions;
export const soutienSliceName = soutienSlice.name;

export const selectCampagne = (state: AppState) => state.soutien.campagne;

export default soutienSlice.reducer;
