/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import PubliqueApi from './api';

export const fetchCampagne = createAsyncThunk(
  `publique/fetchCampagne`,
  async (parameters: { campagneUniqueId: string }) => {
    const response = await PubliqueApi.fetchCampagne(
      parameters.campagneUniqueId
    );
    return response;
  }
);
