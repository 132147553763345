import api from 'backend/Api';
import { CampagnePourInscription, Inscription } from 'models/voteModel';
import qs from 'qs';

const apiRoute = `soutien`;

const fetchCampagne = async (campagneUniqueId: string) => {
  const response = await api.get<{ Campagne: CampagnePourInscription }>(
    `${apiRoute}/${campagneUniqueId}`
  );
  return response.data;
};

export interface AddInscriptionParameters {
  courriel: string;
  nom: string;
  prenom: string;
  message?: string;
  campagneId: number;
  poids: number;
  origin: string;
}

const addInscription = async (parameters: AddInscriptionParameters) => {
  const { campagneId, ...rest } = parameters;
  const data = qs.stringify(rest);
  const response = await api.post<Inscription>(
    `${apiRoute}/Inscription/${campagneId}`,
    data
  );
  return response.data;
};

export interface AideParameters {
  courriel: string;
  nom: string;
  prenom: string;
  message?: string;
  campagneId: number;
}

const aide = async (parameters: AideParameters) => {
  const { campagneId, ...rest } = parameters;
  const data = qs.stringify(rest);
  await api.post(`${apiRoute}/Aide/${campagneId}`, data);
};

const recuperer = async (parameters: {
  campagneId: number;
  courriel: string;
  origin: string;
}) => {
  const { campagneId, ...rest } = parameters;
  const data = qs.stringify(rest);
  await api.post(`${apiRoute}/recuperer/${campagneId}`, data);
};

export default {
  fetchCampagne,
  addInscription,
  recuperer,
  aide,
};
