import { Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { FunctionComponent } from 'react'
import { ErrorBoundary as ReactErrorBoundary, FallbackProps } from 'react-error-boundary'


const GlobalErrorFallback: FunctionComponent<FallbackProps> = ({ resetErrorBoundary }) => (
    <div style={{
        display: 'flex',

        justifyContent: 'center',
        alignItems: 'center',
    }}>

        <Alert style={{ flex: 0.8, paddingTop: '30px', paddingBottom: '30px' }} severity='error' action={
            <Button color="inherit" size="small" onClick={resetErrorBoundary}>
                Ré-essayer
            </Button>
        }
        >
            Désolé, une erreur est survenu.
        </Alert>
    </div>
)


const ErrorBoundary: FunctionComponent = ({ children }) => (
    <ReactErrorBoundary FallbackComponent={GlobalErrorFallback}>
        {children}
    </ReactErrorBoundary>
)

export default ErrorBoundary;