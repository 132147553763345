import { CampagneListItem, ScrutinAdmin } from 'models/adminModel';
import { schema } from 'normalizr';

// Définitions pour Normalizr

// const campagneProcessStrategy: schema.StrategyFunction<CampagneListItem> = (
//   value,
//   parent,
//   key
// ) => {
//   switch (key) {
//     case 'Scrutins':
//       return { ...value, Scrutins: [parent.id] };
//     default:
//       return { ...value };
//   }
// };

// const campagneMergeStrategy: schema.MergeFunction = (entityA, entityB) => {
//   return {
//     ...entityA,
//     ...entityB,
//     Scrutins: [...(entityA.Scrutins || []), ...(entityB.Scrutins || [])],
//   };
// };

export const scrutinSchema = new schema.Entity<ScrutinAdmin>(
  'Scrutins',
  {},
  {
    idAttribute: 'Id',
  }
);

export const campagnesSchema = new schema.Entity<CampagneListItem>(
  'Campagnes',
  { Scrutins: [scrutinSchema] },

  {
    idAttribute: 'Id',
    // processStrategy: campagneProcessStrategy,
    // mergeStrategy: campagneMergeStrategy,
  }
);

export type AdminCampagnesSliceEntities = {
  Campagnes: {
    [campagneId: number]: CampagneListItem;
  };
  Scrutins: {
    [scrutinId: number]: ScrutinAdmin;
  };
};
