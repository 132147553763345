/* eslint-disable import/prefer-default-export */
import { Middleware, AnyAction } from 'redux';
import { logoff, login } from './slice';

export const loginStorageMiddleware: Middleware = () => (next) => (
  action: AnyAction
) => {
  if (login.fulfilled.match(action)) {
    sessionStorage.setItem('user', JSON.stringify(action.payload));
  } else if (logoff.match(action)) {
    sessionStorage.clear();
  }
  return next(action);
};
