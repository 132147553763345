import api from 'backend/Api';
import { Campagne } from 'models/voteModel';

const apiRoute = `publique`;

const fetchCampagne = async (campagneUniqueId: string) => {
  const response = await api.get<{ Campagne: Campagne }>(
    `${apiRoute}/${campagneUniqueId}`
  );
  return response.data;
};

export default {
  fetchCampagne,
};
