// On réassigne state mais c'est prévu par redux-toolkit
/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, AppState } from 'store';
import { defaultLocale } from 'i18n';
import { TraductionValeur } from 'models/voteModel';
import TraductionApi from './api';

// Traduction[Entity][OwnerId][Propriete]

export interface TraductionEntityIndexer {
  [entity: string]: TraductionOwnerIndexer;
}

export interface TraductionOwnerIndexer {
  [ownerId: number]: TraductionProprieteIndexer;
}

export interface TraductionProprieteIndexer {
  [propriete: string]: string;
}

export interface TraductionSlice {
  codei18n: string;
  traductions: TraductionEntityIndexer; // Les traduction sont transformé en
  // un dictionnaire pour simplifier
  // les recherches
}

const initialState: TraductionSlice = {
  codei18n: defaultLocale,
  traductions: {},
};

export const traductionSlice = createSlice({
  name: 'traduction',
  initialState,
  reducers: {
    loadTraduction: (state, action: PayloadAction<TraductionValeur[]>) => {
      state.codei18n = defaultLocale;
      state.traductions = {};

      if (action.payload.length > 0) {
        state.codei18n = action.payload[0].Locale.Code_i18n;

        // Convertie les traductions en un dictionnaire
        // [Entity][OwnerId][Propriete]
        state.traductions = action.payload.reduce<TraductionEntityIndexer>(
          (accumulator, item) => {
            const step = { ...accumulator };
            step[item.Entite.Nom] = step[item.Entite.Nom] || {};
            step[item.Entite.Nom][item.OwnerId] =
              step[item.Entite.Nom][item.OwnerId] || {};
            step[item.Entite.Nom][item.OwnerId][item.Propriete.Nom] =
              item.Texte;
            return step;
          },
          {}
        );
      }
    },
  },
});

export const { loadTraduction } = traductionSlice.actions;

export const selectTraduction = (state: AppState) => ({
  codei18n: state.traduction.codei18n,
  traductions: state.traduction.traductions,
});

export const loadTraductionsAsync = (
  locale: string,
  token: string
): AppThunk => (dispatch) => {
  TraductionApi.getTraduction(
    locale,
    token,
    (traductions: TraductionValeur[]) => {
      dispatch(loadTraduction(traductions));
    },
    // eslint-disable-next-line no-console
    () => console.error(`Unable to load translation for locale ${locale}`)
  );
};

export default traductionSlice.reducer;
