// On réassigne state mais c'est prévu par redux-toolkit
/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'store';
import { colors } from '@material-ui/core';
import { ModalName, UiSlice, UiSliceLoader } from './types';

const initialState: UiSlice = {
  loaders: {},
  modals: {},
  theme: { primary: colors.cyan[800], secondary: colors.teal[400] },
};

const initialLoaderState: UiSliceLoader = {
  loading: false,
  error: undefined,
};

type AsyncState = 'pending' | 'fulfilled' | 'rejected';

const isAsyncActionOfType = (asyncState: AsyncState) => (
  action: AnyAction
): action is AnyAction => action.type.endsWith(asyncState);

const isActionWithTheme = () => (action: AnyAction): action is AnyAction =>
  Boolean(action?.payload?.Campagne?.PreferencesCampagne);

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    openModal: (
      state,
      action: PayloadAction<{ name: ModalName; data?: unknown }>
    ) => {
      state.modals[action.payload.name] = {
        open: true,
        data: action.payload.data,
      };
    },
    closeModal: (state, action: PayloadAction<ModalName>) => {
      state.modals[action.payload] = { open: false };
    },
    clearError: (state, action: PayloadAction<string>) => {
      state.loaders[action.payload] = {
        ...state.loaders[action.payload],
        error: undefined,
      };
    },
    alterTheme: (
      state,
      action: PayloadAction<{ primary: string; secondary: string }>
    ) => {
      state.theme = { ...action.payload };
      return state;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(fetchScrutins.fulfilled, (state, action) => {
    //   if (action.payload.Scrutins[0]?.CampagnePreferencesCampagne) {
    //     state.urlLogo =
    //       action.payload.Scrutins[0]?.CampagnePreferencesCampagne.UrlLogo;
    //     const {
    //       PrimaryColor,
    //       SecondaryColor,
    //     } = action.payload.Scrutins[0]?.CampagnePreferencesCampagne.Theme;
    //     state.theme = { primary: PrimaryColor, secondary: SecondaryColor };
    //   }

    //   return state;
    // });

    builder.addMatcher(isActionWithTheme(), (state, action) => {
      state.urlLogo = action.payload.Campagne.PreferencesCampagne.UrlLogo;
      const {
        PrimaryColor,
        SecondaryColor,
      } = action.payload.Campagne.PreferencesCampagne.Theme;
      state.theme = { primary: PrimaryColor, secondary: SecondaryColor };

      return state;
    });

    builder.addMatcher(
      isAsyncActionOfType('pending'),
      (state, action: AnyAction) => {
        const owner = action.type.toString().slice(0, -8);
        state.loaders[owner] = { loading: true, error: undefined };
      }
    );
    builder.addMatcher(
      isAsyncActionOfType('fulfilled'),
      (state, action: AnyAction) => {
        const owner = action.type.toString().slice(0, -10);
        state.loaders[owner] = { loading: false };
      }
    );
    builder.addMatcher(
      isAsyncActionOfType('rejected'),
      (state, action: AnyAction) => {
        const owner = action.type.toString().slice(0, -9);
        state.loaders[owner] = {
          loading: false,
          error:
            action.error.message ||
            // action.payload ||
            'Une erreur est survenue lors de votre requête',
        };
      }
    );
  },
});

export const {
  openModal,
  closeModal,
  clearError,
  alterTheme,
} = uiSlice.actions;

export const selectUiLoader = (owner: string) => (state: AppState) => {
  if (owner in state.ui.loaders) {
    return state.ui.loaders[owner];
  }
  return initialLoaderState;
};

export const selectUiModal = (name: ModalName) => (state: AppState) => {
  if (name in state.ui.modals) {
    return state.ui.modals[name].open;
  }
  return false;
};

export const selectUiModals = (state: AppState) => state.ui.modals;
export const selectTheme = (state: AppState) => state.ui.theme;
export const selectUrlLogo = (state: AppState) => state.ui.urlLogo;
export const selectModalData = (name: ModalName) => (state: AppState) =>
  state.ui.modals[name]?.data;

export default uiSlice.reducer;
