// On réassigne state mais c'est prévu par redux-toolkit
/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { createSlice } from '@reduxjs/toolkit';
import { AppState } from 'store';
import _ from 'lodash';
import { Candidat } from 'models/voteModel';
import { editCandidat, fetchAllCandidats } from './actions';

export interface CandidatSlice {
  Candidats: {
    [id: number]: Candidat;
  };
  allCandidats: number[];
}

const initialState: CandidatSlice = {
  Candidats: {},
  allCandidats: [],
};

export const candidatsSlice = createSlice({
  name: 'admin/candidats',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllCandidats.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
      state.allCandidats = action.payload.result;
      return state;
    });
    builder.addCase(editCandidat.fulfilled, (state, action) => {
      state.Candidats[action.payload.Id] = action.payload;
      if (!state.allCandidats.includes(action.payload.Id)) {
        state.allCandidats.unshift(action.payload.Id);
      }
      return state;
    });
  },
});

export const { actions } = candidatsSlice;

export const selectCandidats = (campagneId: number) => (state: AppState) => {
  // TODO: Generalize
  const ids = state.admin.candidats.allCandidats;
  return ids
    .map((id) => state.admin.candidats.Candidats[id])
    .filter((x) => x?.CampagneIds.includes(campagneId));
};

export const selectInscriptionById = (id: number) => (state: AppState) =>
  state.admin.inscription.Inscriptions[id];

export default candidatsSlice.reducer;
