import { createAsyncThunk } from '@reduxjs/toolkit';
import { ScrutinAdminQuestion } from 'models/adminModel';
import { TypeScrutin } from 'models/voteModel';
import { normalize } from 'normalizr';
import AdminApi from './api';
import { campagnesSchema, scrutinSchema } from './schemas';

export const fetchAllCampagnes = createAsyncThunk(
  `admin/campagne/fetchAllCampagnes`,
  async (token: string) => {
    const response = await AdminApi.fetchAllCampagnes(token);
    return normalize(response, [campagnesSchema]);
  }
);

export const fetchCampagneById = createAsyncThunk(
  `admin/campagne/fetchCampagneById`,
  async (parameters: { id: number; token: string }) => {
    const response = await AdminApi.fetchCampagneById(
      parameters.id,
      parameters.token
    );
    return normalize(response, campagnesSchema);
  }
);

export interface UpdateCampagneFormFields {
  titre: string;
  dateDebut: Date;
  dateFin: Date;
  ouvertureDuVote: Date;
  fermetureDuVote: Date;
  uniqueId: string;
  libelleAbstention?: string;
  titrePageAccueil?: string;
}

export const addCampagne = createAsyncThunk(
  `admin/campagne/addCampagne`,
  async (parameters: { token: string } & UpdateCampagneFormFields) => {
    const response = await AdminApi.addCampagne(parameters);
    return normalize(response, campagnesSchema);
  }
);

export const updateCampagne = createAsyncThunk(
  `admin/campagne/updateCampagne`,
  async (
    parameters: {
      id: number;
      token: string;
    } & UpdateCampagneFormFields
  ) => {
    const response = await AdminApi.updateCampagne(parameters);
    return normalize(response, campagnesSchema);
  }
);

export interface UpdateEspacePubliqueParameters {
  permettreSectionPublique: boolean;
  accueilPublique?: string;
}

export const updateEspacePublique = createAsyncThunk(
  `admin/campagne/updateEspacePublique`,
  async (
    parameters: {
      id: number;
      token: string;
    } & UpdateEspacePubliqueParameters
  ) => {
    const response = await AdminApi.updateEspacePublique(parameters);
    return normalize(response, campagnesSchema);
  }
);

export const deleteCampagne = createAsyncThunk(
  `admin/campagne/deleteCampagne`,
  async (parameters: { id: number; token: string }) => {
    await AdminApi.deleteCampagne(parameters);
    return parameters.id;
  }
);
export const deleteBulletins = createAsyncThunk(
  `admin/campagne/deleteBulletins`,
  async (parameters: { id: number; token: string }) => {
    const response = await AdminApi.deleteBulletins(parameters);
    return normalize(response, campagnesSchema);
  }
);

export const addScrutin = createAsyncThunk(
  `admin/campagne/addScrutin`,
  async (parameters: {
    titre: string;
    idUnique: string;
    campagneId: number;
    question: string;
    typeScrutin: TypeScrutin;
    token: string;
  }) => {
    const response = await AdminApi.addScrutin(parameters);
    return normalize(response, scrutinSchema);
  }
);

export const fetchScrutinById = createAsyncThunk(
  `admin/campagne/fetchScrutinById`,
  async (parameters: { id: number; token: string }) => {
    const response = await AdminApi.fetchScrutinById(
      parameters.id,
      parameters.token
    );
    return normalize(response, scrutinSchema);
  }
);

export const fetchCampagneByScrutinId = createAsyncThunk(
  `admin/campagne/fetchCampagneByScrutinId`,
  async (parameters: { id: number; token: string }) => {
    const response = await AdminApi.fetchCampagneByScrutinId(
      parameters.id,
      parameters.token
    );
    return normalize(response, campagnesSchema);
  }
);

export const activateScrutin = createAsyncThunk(
  `admin/campagne/ActiverScrutin`,
  async (parameters: { id: number; token: string; nbConnections: number }) => {
    const response = await AdminApi.activateScrutin(
      parameters.id,
      parameters.nbConnections,
      parameters.token
    );
    return normalize(response, scrutinSchema);
  }
);

export const reprendreScrutin = createAsyncThunk(
  `admin/campagne/ReprendreScrutin`,
  async (parameters: { id: number; token: string }) => {
    const response = await AdminApi.reprendreScrutin(
      parameters.id,
      parameters.token
    );
    return normalize(response, scrutinSchema);
  }
);

export const closeScrutin = createAsyncThunk(
  `admin/campagne/CloseScrutin`,
  async (parameters: { id: number; token: string }) => {
    const response = await AdminApi.closeScrutin(
      parameters.id,
      parameters.token
    );
    return normalize(response, scrutinSchema);
  }
);

export const publishScrutin = createAsyncThunk(
  `admin/campagne/PublishScrutin`,
  async (parameters: { id: number; token: string }) => {
    const response = await AdminApi.publishScrutin(
      parameters.id,
      parameters.token
    );
    return normalize(response, scrutinSchema);
  }
);

export const forcerStatutScrutin = createAsyncThunk(
  `admin/campagne/ForcerStatutScrutin`,
  async (parameters: { id: number; refId: number; token: string }) => {
    const response = await AdminApi.forcerStatutScrutin(
      parameters.id,
      parameters.refId,
      parameters.token
    );
    return normalize(response, scrutinSchema);
  }
);

export const diffuserResultats = createAsyncThunk(
  `admin/campagne/diffuserResultats`,
  async (parameters: { id: number; token: string }) => {
    const response = await AdminApi.diffuserResultats(
      parameters.id,
      parameters.token
    );
    return normalize(response, scrutinSchema);
  }
);

export const genererBulletins = createAsyncThunk(
  `admin/campagne/diffuserResultats`,
  async (parameters: {
    campagneId: string;
    quantite: number;
    segments: string[];
    token: string;
    tokenSeulement?: boolean;
  }) => AdminApi.genererBulletins(parameters)
);

export const fetchAllSegments = createAsyncThunk(
  `admin/campagne/fetchAllSegments`,
  async (parameters: { campagneId: number; token: string }) =>
    AdminApi.fetchAllSegments(parameters.campagneId, parameters.token)
);

export const setTheme = createAsyncThunk(
  `admin/campagne/setTheme`,
  async (parameters: {
    id: number;
    themeId: number;
    urlLogo: string | null;
    token: string;
  }) => {
    const response = await AdminApi.setTheme(parameters);
    return normalize(response, campagnesSchema);
  }
);

export const switchToCourriel = createAsyncThunk(
  `admin/campagne/switchToCourriel`,
  async (parameters: { id: number; token: string }) => {
    const response = await AdminApi.switchToCourriel(parameters);
    return normalize(response, campagnesSchema);
  }
);

export const switchToEnDirect = createAsyncThunk(
  `admin/campagne/switchToEnDirect`,
  async (parameters: { id: number; token: string }) => {
    const response = await AdminApi.switchToEnDirect(parameters);
    return normalize(response, campagnesSchema);
  }
);

export interface UpdateScrutinFormFields {
  titre: string;
  avecCandidats: boolean;
  uniqueId: string;
  segmente: boolean;
  description?: string;
  explication?: string;
  abstentionPermise: boolean;
  messageAvantVote?: string;
}

export const updateScrutin = createAsyncThunk(
  `admin/campagne/updateScrutin`,
  async (
    parameters: {
      id: number;
      token: string;
    } & UpdateScrutinFormFields
  ) => {
    const response = await AdminApi.updateScrutin(parameters);
    return response;
  }
);

export interface UpdateQuestionsScrutinFormFields {
  questions: ScrutinAdminQuestion[];
}

export const updateQuestionsScrutin = createAsyncThunk(
  `admin/campagne/updateQuestionsScrutin`,
  async (
    parameters: {
      id: number;
      token: string;
    } & UpdateQuestionsScrutinFormFields
  ) => {
    const response = await AdminApi.updateQuestionsScrutin(parameters);
    return response;
  }
);

export const addCandidatReponse = createAsyncThunk(
  `admin/campagne/addCandidatReponse`,
  async (parameters: {
    id: number;
    token: string;
    nom: string;
    prenom: string;
  }) => {
    const response = await AdminApi.addCandidatReponse(parameters);
    return response;
  }
);

export const setOrdreScrutins = createAsyncThunk(
  `admin/campagne/setOrdreScrutins`,
  async (parameters: { id: number; scrutinsIds: number[]; token: string }) => {
    const response = await AdminApi.setOrdreScrutins(parameters);
    return normalize(response, campagnesSchema);
  }
);

export const fetchVentilationScrutin = createAsyncThunk(
  `admin/campagne/fetchVentilationScrutin`,
  async (parameters: { id: number; token: string }) => {
    const response = await AdminApi.fetchVentilationScrutin(
      parameters.id,
      parameters.token
    );
    return response;
  }
);

export const fetchApiInfo = createAsyncThunk(`admin/fetchApiInfo`, async () => {
  const response = await AdminApi.fetchApiInfo();
  return response;
});
