// On réassigne state mais c'est prévu par redux-toolkit
/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from 'store';
import { User } from 'models/systemModel';
import AccountApi from './api';

const sliceName = 'admin/account';
export interface AccountSlice {
  user?: User;
}

const initialState: AccountSlice = {};

interface loginParameters {
  courriel: string;
  password: string;
}
export const login = createAsyncThunk(
  `${sliceName}/login`,
  async (parameters: loginParameters) => AccountApi.login(parameters.courriel, parameters.password)
);

export const accountSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    logoff: (state) => {
      state.user = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = action.payload;
    });
  },
});

export const { logoff } = accountSlice.actions;
export const accountSliceName = accountSlice.name;

export const selectUser = (state: AppState) => state.admin.account.user;

export default accountSlice.reducer;
