// On réassigne state mais c'est prévu par redux-toolkit
/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { createSlice } from '@reduxjs/toolkit';
import { Campagne } from 'models/voteModel';
import { AppState } from 'store';
import { fetchCampagne } from './actions';
import { ScrutinSliceItem } from './model';

const sliceName = 'publique';

export interface PubliqueSlice {
  Campagne?: Campagne;
}

const initialState: PubliqueSlice = {};

export const publiqueSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCampagne.fulfilled, (state, action) => ({
      ...state,
      Campagne: action.payload.Campagne,
    }));
  },
});

// export const {} = publiqueSlice.actions;

export const publiqueSliceName = publiqueSlice.name;

export const selectCampagne = (state: AppState) => state.publique.Campagne;
export const selectAvecCandidats = (state: AppState) =>
  Boolean(
    state.publique.Campagne?.Scrutins.some((s) =>
      s.Questions.some((q) => q.Reponses.some((r) => Boolean(r.Candidat)))
    )
  );

export const selectCandidats: (state: AppState) => ScrutinSliceItem[] = (
  state
) =>
  state.publique.Campagne?.Scrutins.map((s) => ({
    scrutinId: s.Id,
    titre: s.Titre,
    postes: s.Questions.filter((q) =>
      q.Reponses.some((r) => Boolean(r.Candidat))
    ).map((q) => ({
      id: q.Id,
      titre: q.Texte,
      candidats: q.Reponses.filter((r) => Boolean(r.Candidat)).map(
        (r) => r.Candidat
      ),
    })),
  })).filter((s) => s.postes.some((p) => p.candidats.length > 0)) || [];

export default publiqueSlice.reducer;
