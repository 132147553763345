/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import HomeApi from './api';

export const loginWithCode = createAsyncThunk(
  `home/loginWithCode`,
  async (parameters: { codeAccess: string }) => {
    const response = await HomeApi.loginWithCode(parameters.codeAccess);
    return response;
  }
);

export const fetchCampagne = createAsyncThunk(
  `home/fetchCampagne`,
  async (parameters: { token: string }) => {
    const response = await HomeApi.fetchCampagne(parameters.token);
    return response;
  }
);
