import api from 'backend/Api';
import qs from 'qs';
import { Bulletin } from 'models/voteModel';
import { ChoixReponse } from './slice';

const apiRoute = `Vote`;

const sendVotes = async (
  token: string,
  scrutinUniqueId: string,
  votes: ChoixReponse[]
) => {
  const data = {
    token,
    scrutinUniqueId,
    votes,
  };

  const response = await api.post<Bulletin>(apiRoute, qs.stringify(data));
  return response.data;
};

export default {
  sendVotes,
};
