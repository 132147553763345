import api, { Api } from 'backend/Api';
import { EmailTemplate } from 'models/adminModel';
import qs from 'qs';

const apiRoute = `email`;

const fetchAllTemplates = async (parameters: {
  token: string;
  campagneId: number;
}) => {
  const response = await api.get<EmailTemplate[]>(
    `${apiRoute}/Templates/${parameters.campagneId}`,
    Api.bearerAuth(parameters.token)
  );
  return response.data;
};

const sendEmail = async (parameters: {
  token: string;
  inscriptionId: number;
  sujet: string;
  message: string;
  destination: 'Principale' | 'Alternative';
  lienVote: string;
}) => {
  const { token, destination, ...data } = parameters;
  const courrielAlternatif = destination === 'Alternative';
  const response = await api.post<EmailTemplate[]>(
    `${apiRoute}/Send`,
    qs.stringify({ ...data, courrielAlternatif }),
    Api.bearerAuth(token)
  );
  return response.data;
};

export default {
  fetchAllTemplates,
  sendEmail,
};
