/* eslint-disable import/prefer-default-export */
import { useEffect } from 'react';
import 'moment/locale/fr';
import Moment from 'react-moment';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectTraduction } from 'features/traduction/slice';
import numeral from 'numeral'


// // Sets the moment instance to use.
// Moment.globalMoment = moment;

// Set the locale for every react-moment instance to French.
// Moment.globalLocale = 'fr';

// Set the output format for every react-moment instance.
Moment.globalFormat = 'D MMM YYYY';

// TODO: Set quand toutes les dates vont être en UTC
// // Set the output timezone for local for every instance.
// Moment.globalLocal = true;

// Use a <span> tag for every react-moment instance.
Moment.globalElement = 'span';

interface DatesProperties {
  date: Date | string;
}

const DateHeure: React.FC<DatesProperties> = ({ date }) => (
  <Moment format="LLLL" >
    {date}
  </Moment>
);

const LocalisationSetter = () => {
  const traduction = useSelector(selectTraduction)

  useEffect(() => {
    moment.locale(traduction.codei18n);
    numeral.locale(traduction.codei18n);

  }, [traduction.codei18n])

  return null;
}

export { DateHeure, LocalisationSetter };
