import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import homeReducer from 'features/home/slice';
import soutienSliceReducer from 'features/soutien/slice';
import themesReducer from 'features/themes/slice';
import traductionReducer from 'features/traduction/slice';
import uiReducer from 'features/ui/slice';
import voteReducer from 'features/vote/slice';
import publiqueReducer from 'features/espacePublique/slice';
import adminReducer from 'moduleAdmin';
import { loginStorageMiddleware } from 'moduleAdmin/features/account/middlewares';
import { getUserInSession } from 'moduleAdmin/features/account/utils';
import { useDispatch } from 'react-redux';

const userInSession = getUserInSession();

export const store = configureStore({
  preloadedState: {
    admin: {
      account: { user: userInSession },
    },
  },
  reducer: {
    ui: uiReducer,
    admin: adminReducer,
    home: homeReducer,
    themes: themesReducer,
    publique: publiqueReducer,
    soutien: soutienSliceReducer,
    vote: voteReducer,
    traduction: traductionReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(loginStorageMiddleware),
});

export type AppState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
