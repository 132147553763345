import api from 'backend/Api';
import { Bulletin, Campagne, Scrutin } from 'models/voteModel';
import qs from 'qs';

const loginWithCode = async (codeAccess: string) => {
  const data = qs.stringify({ codeAccess });
  const response = await api.post<{ token: string }>(
    `scrutin/loginWithCode`,
    data
  );
  return response.data;
};

export interface FetchCampagneResult {
  Campagne: Campagne;
  Bulletin?: Bulletin;
  ScrutinActif?: Scrutin;
}

const fetchCampagne = async (token: string) => {
  const response = await api.get<FetchCampagneResult>(
    `Campagne/?token=${token}`
  );
  return response.data;
};

export default {
  loginWithCode,
  fetchCampagne,
};
