// On réassigne state mais c'est prévu par redux-toolkit
/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { createSlice } from '@reduxjs/toolkit';
import { AppState } from 'store';
import _ from 'lodash';
import { fetchThemeById, fetchAllThemes } from './actions';
import { ThemeSliceEntities } from './schemas';

export interface ThemesSlice extends Readonly<ThemeSliceEntities> {
  fetchAllThemes: number[];
}

const initialState: ThemesSlice = {
  Themes: [],
  fetchAllThemes: [],
};

export const themesSclice = createSlice({
  name: 'themes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllThemes.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
      state.fetchAllThemes = action.payload.result;
      return state;
    });
    builder.addCase(fetchThemeById.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
      return state;
    });
  },
});

export const selectThemes = (state: AppState) => {
  const ids = state.themes.fetchAllThemes;
  return ids.map((id) => state.themes.Themes[id]);
};

export const selectTheme = (id: number) => (state: AppState) =>
  state.themes.Themes[id];

export default themesSclice.reducer;
