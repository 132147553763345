import { createAsyncThunk } from '@reduxjs/toolkit';
import { EmailTemplate } from 'models/adminModel';
import { normalize, schema } from 'normalizr';
import api from './api';

// TODO: Generalize
const emailTemplateSchema = new schema.Entity<EmailTemplate>(
  'Templates',
  {},
  {
    idAttribute: 'Id',
  }
);

export const fetchAllTemplates = createAsyncThunk(
  `admin/email/fetchAllTemplates`,
  async (parameters: { token: string; campagneId: number }) => {
    const response = await api.fetchAllTemplates(parameters);
    return normalize(response, [emailTemplateSchema]);
  }
);

export const sendEmail = createAsyncThunk(
  `admin/email/sendEmail`,
  async (parameters: {
    token: string;
    inscriptionId: number;
    destination: 'Principale' | 'Alternative';
    sujet: string;
    message: string;
    lienVote: string;
  }) => {
    await api.sendEmail(parameters);
  }
);
