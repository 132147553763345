import api, { Api } from 'backend/Api';
import { CampagneListItem } from 'models/adminModel';
import { Inscription } from 'models/voteModel';
import qs from 'qs';

const apiRoute = `inscription`;

const fetchAllInscriptions = async (parameters: {
  token: string;
  campagneId: number;
}) => {
  const response = await api.get<Inscription[]>(
    `${apiRoute}/GetAll/${parameters.campagneId}`,
    Api.bearerAuth(parameters.token)
  );
  return response.data;
};

const donnerDroitVote = async (parameters: { token: string; id: number }) => {
  const response = await api.post<Inscription>(
    `${apiRoute}/DonnerDroitVote/${parameters.id}`,
    undefined,
    Api.bearerAuth(parameters.token)
  );
  return response.data;
};

const retirerDroitVote = async (parameters: { token: string; id: number }) => {
  const response = await api.post<Inscription>(
    `${apiRoute}/RetirerDroitVote/${parameters.id}`,
    undefined,
    Api.bearerAuth(parameters.token)
  );
  return response.data;
};

const donnerDroitVoteATous = async (parameters: {
  token: string;
  campagneId: number;
}) => {
  const response = await api.post<Inscription[]>(
    `${apiRoute}/DonnerDroitVoteATous/${parameters.campagneId}`,
    undefined,
    Api.bearerAuth(parameters.token)
  );
  return response.data;
};

const retirerDroitVoteATous = async (parameters: {
  token: string;
  campagneId: number;
}) => {
  const response = await api.post<Inscription[]>(
    `${apiRoute}/RetirerDroitVoteATous/${parameters.campagneId}`,
    undefined,
    Api.bearerAuth(parameters.token)
  );
  return response.data;
};

const synchroniserDroitVoteAvecPresences = async (parameters: {
  token: string;
  campagneId: number;
}) => {
  const response = await api.post<Inscription[]>(
    `${apiRoute}/SynchroniserDroitVoteAvecPresences/${parameters.campagneId}`,
    undefined,
    Api.bearerAuth(parameters.token)
  );
  return response.data;
};

const importInscriptions = async (parameters: {
  token: string;
  file: File;
  campagneId: number;
}) => {
  const { token, file, campagneId } = parameters;

  // const config = {
  //   headers: {
  //     ...Api.bearerAuth(token).headers,
  //     'Content-Type': 'multipart/form-data',
  //   },
  // };

  const formData = new FormData();
  formData.append('files', file, file.name);

  const response = await api.post<Inscription[]>(
    `${apiRoute}/ImportFile/${campagneId}`,
    formData,
    Api.bearerAuth(token)
  );
  return response.data;
};

const genererBulletinsPourInscriptions = async (parameters: {
  token: string;
  campagneId: number;
  ids: number[];
}) => {
  const { token, campagneId, ...data } = parameters;
  const response = await api.post<string[]>(
    `${apiRoute}/GenererBulletins/${campagneId}`,
    qs.stringify(data),
    Api.bearerAuth(token)
  );
  return response.data;
};

const genererBulletinPourUneInscription = async (parameters: {
  token: string;
  inscriptionId: number;
}) => {
  const response = await api.get<string>(
    `${apiRoute}/GenererUnBulletin/${parameters.inscriptionId}`,
    Api.bearerAuth(parameters.token)
  );
  return response.data;
};

const demarrerPrisePresences = async (parameters: {
  token: string;
  campagneId: number;
  reponseChallenge: number;
}) => {
  const { token, campagneId, ...data } = parameters;
  const response = await api.post<CampagneListItem>(
    `${apiRoute}/demarrerPrisePresences/${campagneId}`,
    qs.stringify(data),
    Api.bearerAuth(token)
  );
  return response.data;
};

const arreterPrisePresences = async (parameters: {
  token: string;
  campagneId: number;
}) => {
  const { token, campagneId } = parameters;
  const response = await api.post<CampagneListItem>(
    `${apiRoute}/arreterPrisePresences/${campagneId}`,
    undefined,
    Api.bearerAuth(token)
  );
  return response.data;
};

const admettreInscription = async (parameters: {
  token: string;
  id: number;
  origin: string;
}) => {
  const { token, id, origin } = parameters;
  const response = await api.post<Inscription>(
    `${apiRoute}/admettre/${id}`,
    qs.stringify({ origin }),
    Api.bearerAuth(token)
  );
  return response.data;
};

const rejetterInscription = async (parameters: {
  token: string;
  id: number;
  reponse?: string;
}) => {
  const { token, id, reponse } = parameters;
  const response = await api.post<Inscription>(
    `${apiRoute}/rejetter/${id}`,
    qs.stringify({ reponse }),
    Api.bearerAuth(token)
  );
  return response.data;
};

export interface AddInscriptionParameters {
  token: string;
  courriel: string;
  courrielAlternatif?: string;
  nom: string;
  prenom: string;
  message?: string;
  campagneId: number;
  poids: number;
  segments: string[];
}

const addInscription = async (parameters: AddInscriptionParameters) => {
  const { token, campagneId, ...rest } = parameters;
  const data = qs.stringify(rest);
  const response = await api.post<Inscription>(
    `${apiRoute}/${campagneId}`,
    data,
    Api.bearerAuth(token)
  );
  return response.data;
};

export interface EditInscriptionParameters {
  CourrielAlternatif?: string;
  Nom: string;
  Prenom: string;
  Message?: string;
  Poids: number;
  segments: string[];
}

const editInscription = async (parameters: {
  token: string;
  inscription: EditInscriptionParameters;
}) => {
  const response = await api.put<Inscription>(
    `${apiRoute}`,
    qs.stringify(parameters.inscription),
    Api.bearerAuth(parameters.token)
  );
  return response.data;
};

const updateCourriel = async (parameters: {
  token: string;
  inscriptionId: number;
  courriel: string;
}) => {
  const { token, inscriptionId, courriel } = parameters;
  const response = await api.patch<Inscription>(
    `${apiRoute}/updateCourriel/${inscriptionId}`,
    qs.stringify({ courriel }),
    Api.bearerAuth(token)
  );
  return response.data;
};

const radierInscription = async (parameters: {
  token: string;
  inscriptionId: number;
}) => {
  const { token, inscriptionId } = parameters;
  const response = await api.post<Inscription>(
    `${apiRoute}/radier/${inscriptionId}`,
    undefined,
    Api.bearerAuth(token)
  );
  return response.data;
};

const deleteInscription = async (parameters: {
  token: string;
  inscriptionId: number;
}) => {
  const { token, inscriptionId } = parameters;
  await api.delete(`${apiRoute}/${inscriptionId}`, Api.bearerAuth(token));
  return inscriptionId;
};

export default {
  fetchAllInscriptions,
  donnerDroitVote,
  retirerDroitVote,
  donnerDroitVoteATous,
  retirerDroitVoteATous,
  importInscriptions,
  genererBulletinsPourInscriptions,
  genererBulletinPourUneInscription,
  demarrerPrisePresences,
  arreterPrisePresences,
  synchroniserDroitVoteAvecPresences,
  admettreInscription,
  rejetterInscription,
  addInscription,
  editInscription,
  updateCourriel,
  radierInscription,
  deleteInscription,
};
