// On réassigne state mais c'est prévu par redux-toolkit
/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { EtatInscription } from 'models/enums';
import { Inscription } from 'models/voteModel';
import { AppState } from 'store';
import {
  addInscription,
  admettreInscription,
  deleteInscription,
  donnerDroitVote,
  donnerDroitVoteATous,
  editInscription,
  fetchAllInscriptions,
  importInscriptions,
  radierInscription,
  rejetterInscription,
  retirerDroitVote,
  retirerDroitVoteATous,
  synchroniserDroitVoteAvecPresences,
  updateCourriel,
} from './actions';

export interface InscriptionSlice {
  Inscriptions: {
    [id: number]: Inscription;
  };
  allInscriptions: number[];
}

const initialState: InscriptionSlice = {
  Inscriptions: {},
  allInscriptions: [],
};

export const inscriptionSlice = createSlice({
  name: 'admin/inscription',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllInscriptions.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
      state.allInscriptions = action.payload.result;
      return state;
    });
    builder.addCase(importInscriptions.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
      state.allInscriptions = action.payload.result;
      return state;
    });
    builder.addCase(donnerDroitVoteATous.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
      state.allInscriptions = action.payload.result;
      return state;
    });
    builder.addCase(retirerDroitVoteATous.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
      state.allInscriptions = action.payload.result;
      return state;
    });
    builder.addCase(donnerDroitVote.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
      return state;
    });
    builder.addCase(retirerDroitVote.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
      return state;
    });
    builder.addCase(
      synchroniserDroitVoteAvecPresences.fulfilled,
      (state, action) => {
        _.merge(state, action.payload.entities);
        return state;
      }
    );
    builder.addCase(addInscription.fulfilled, (state, action) => {
      state.Inscriptions[action.payload.Id] = action.payload;
      state.allInscriptions.unshift(action.payload.Id);
      return state;
    });
    builder.addCase(admettreInscription.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
      return state;
    });
    builder.addCase(editInscription.fulfilled, (state, action) => {
      state.Inscriptions[action.payload.Id] = action.payload;
      if (!state.allInscriptions.includes(action.payload.Id)) {
        state.allInscriptions.unshift(action.payload.Id);
      }
      return state;
    });
    builder.addCase(updateCourriel.fulfilled, (state, action) => {
      state.Inscriptions[action.payload.Id] = action.payload;
      if (!state.allInscriptions.includes(action.payload.Id)) {
        state.allInscriptions.unshift(action.payload.Id);
      }
      return state;
    });
    builder.addCase(radierInscription.fulfilled, (state, action) => {
      state.Inscriptions[action.payload.Id] = action.payload;
      if (!state.allInscriptions.includes(action.payload.Id)) {
        state.allInscriptions.unshift(action.payload.Id);
      }
      return state;
    });
    builder.addCase(rejetterInscription.fulfilled, (state, action) => {
      delete state.Inscriptions[action.payload];
      state.allInscriptions = state.allInscriptions.filter(
        (id) => id !== action.payload
      );
      return state;
    });
    builder.addCase(deleteInscription.fulfilled, (state, action) => {
      delete state.Inscriptions[action.payload];
      state.allInscriptions = state.allInscriptions.filter(
        (id) => id !== action.payload
      );
      return state;
    });
  },
});

export const { actions } = inscriptionSlice;

export const selectInscriptions = (campagneId: number) => (state: AppState) => {
  // TODO: Generalize
  const ids = state.admin.inscription.allInscriptions;
  return ids
    .map((id) => state.admin.inscription.Inscriptions[id])
    .filter(
      (x) =>
        x?.CampagneId === campagneId &&
        (x?.EtatInscriptionRefId === EtatInscription.CONFIRMEE ||
          x?.EtatInscriptionRefId === EtatInscription.ANNULEE)
    );
};

export const selectInscriptionsEnAttente = (campagneId: number) => (
  state: AppState
) => {
  // TODO: Generalize
  const ids = state.admin.inscription.allInscriptions;
  return ids
    .map((id) => state.admin.inscription.Inscriptions[id])
    .filter(
      (x) =>
        x?.CampagneId === campagneId &&
        x?.EtatInscriptionRefId === EtatInscription.EN_ATTENTE
    );
};

export const selectInscriptionById = (id: number) => (state: AppState) =>
  state.admin.inscription.Inscriptions[id];

export default inscriptionSlice.reducer;
