import api, { Api } from 'backend/Api';
import { Candidat } from 'models/voteModel';
import qs from 'qs';

const apiRoute = `candidat`;

const fetchAllCandidats = async (parameters: {
  token: string;
  campagneId: number;
}) => {
  const response = await api.get<Candidat[]>(
    `${apiRoute}/GetAll/${parameters.campagneId}`,
    Api.bearerAuth(parameters.token)
  );
  return response.data;
};

export interface EditCandidatParameters {
  Id?: number;
  Nom: string;
  Prenom: string;
  SousTitre?: string;
  PhotoUrl?: string;
  Facebook?: string;
  Twitter?: string;
  LinkedIn?: string;
  Web?: string;
  YouTube?: string;
  TexteLong?: string;
}

const editCandidat = async (parameters: {
  token: string;
  campagneId: number;
  candidat: EditCandidatParameters;
}) => {
  const response = await api.put<Candidat>(
    `${apiRoute}/${parameters.campagneId}`,
    qs.stringify(parameters.candidat),
    Api.bearerAuth(parameters.token)
  );
  return response.data;
};

export default {
  fetchAllCandidats,
  editCandidat,
};
