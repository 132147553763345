// On réassigne state mais c'est prévu par redux-toolkit
/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { createSlice } from '@reduxjs/toolkit';
import { AppState } from 'store';
import _ from 'lodash';
import { EmailTemplate } from 'models/adminModel';
import { fetchAllTemplates } from './actions';

export interface EmailSlice {
  Templates: {
    [id: number]: EmailTemplate;
  };
  allTemplates: number[];
}

const initialState: EmailSlice = {
  Templates: {},
  allTemplates: [],
};

export const EmailSlice = createSlice({
  name: 'admin/Email',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllTemplates.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
      state.allTemplates = action.payload.result;
      return state;
    });
  },
});

export const {actions} = EmailSlice;

export const selectTemplates = () => (state: AppState) => state.admin.email.Templates;

export const selectTemplateById = (id: number) => (state: AppState) =>
  state.admin.email.Templates[id];

export default EmailSlice.reducer;
