import Api from 'backend/Api';
import { User } from 'models/systemModel';
import qs from 'qs';

const login = (courriel: string, password: string) => Api.post<User>(
    'security/login',
    qs.stringify({ courriel, password })
  ).then((response) => response.data);

export default { login };
