import { withStyles, Theme, createStyles } from '@material-ui/core';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const styles = (theme: Theme) =>
  createStyles({
    '@global': {
      /**
       * Disable the focus outline, which is default on some browsers like
       * chrome when focusing elements
       */
      '*:focus': {
        outline: 0,
      },
      '.text-white': {
        color: theme.palette.common.white,
      },
    },
  });

function globalStyles() {
  return null;
}

export default withStyles(styles, { withTheme: true })(globalStyles);
