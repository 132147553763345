import { AxiosError } from 'axios';
import Api from 'backend/Api';
import { TraductionValeur } from 'models/voteModel';

const getTraduction = (
  locale: string,
  token: string,
  success: (response: TraductionValeur[]) => void,
  failed: (error: AxiosError) => void
): void => {
  Api.get<TraductionValeur[]>(`traduction/${locale}/?token=${token}`)
    .then(({ data }) => success(data))
    .catch((error: AxiosError) => failed(error));
};

export default { getTraduction };
