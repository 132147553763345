import admin from './admin.json';
import campagne from './campagne.json';
import common from './common.json';
import errors from './errors.json';
import validation from './validation.json';

export default {
  common,
  campagne,
  errors,
  admin,
  validation,
};
