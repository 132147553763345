// On réassigne state mais c'est prévu par redux-toolkit
/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { CampagneListItem, ScrutinAdminReponse } from 'models/adminModel';
import { ResultatsDirectScrutin } from 'models/voteModel';
import { normalize } from 'normalizr';
import { AppState } from 'store';
import {
  arreterPrisePresences,
  demarrerPrisePresences,
} from '../inscription/actions';
import {
  activateScrutin,
  addCampagne,
  addCandidatReponse,
  addScrutin,
  closeScrutin,
  deleteBulletins,
  deleteCampagne,
  diffuserResultats,
  fetchAllCampagnes,
  fetchApiInfo,
  fetchCampagneById,
  fetchCampagneByScrutinId,
  fetchScrutinById,
  fetchVentilationScrutin,
  forcerStatutScrutin,
  publishScrutin,
  reprendreScrutin,
  setOrdreScrutins,
  setTheme,
  switchToCourriel,
  switchToEnDirect,
  updateCampagne,
  updateEspacePublique,
  updateQuestionsScrutin,
  updateScrutin,
} from './actions';
import { AdminCampagnesSliceEntities, campagnesSchema } from './schemas';

export interface AdminCampagnesSlice
  extends Readonly<AdminCampagnesSliceEntities> {
  fetchAllCampagnes: number[];
  VentilationQuestions: Record<number, Record<string, ScrutinAdminReponse[]>>;
  ApiEnvironment?: string;
}

const initialState: AdminCampagnesSlice = {
  Campagnes: {},
  Scrutins: {},
  fetchAllCampagnes: [],
  VentilationQuestions: {},
};

export const adminCampagnesSlice = createSlice({
  name: 'admin/campagne',
  initialState,
  reducers: {
    scrutinModifie: (state, action: PayloadAction<ResultatsDirectScrutin>) => {
      state.Scrutins[action.payload.Id] = {
        ...state.Scrutins[action.payload.Id],
        ...action.payload,
      };
      return state;
    },
    campagneModifie: (state, action: PayloadAction<CampagneListItem>) => {
      const normalized = normalize(action.payload, campagnesSchema);
      _.merge(state, normalized.entities);
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteCampagne.fulfilled, (state, action) => {
      state.fetchAllCampagnes = state.fetchAllCampagnes.filter(
        (id) => id !== action.payload
      );
      state.Campagnes = _.pick(state.Campagnes, state.fetchAllCampagnes);
      return state;
    });
    builder.addCase(deleteBulletins.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
    });
    builder.addCase(updateCampagne.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
    });
    builder.addCase(fetchScrutinById.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
    });
    builder.addCase(closeScrutin.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
      state.VentilationQuestions = {};
    });
    builder.addCase(switchToCourriel.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
    });
    builder.addCase(switchToEnDirect.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
    });
    builder.addCase(updateEspacePublique.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
    });
    builder.addCase(diffuserResultats.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
    });
    builder.addCase(activateScrutin.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
      state.VentilationQuestions = {};
    });
    builder.addCase(reprendreScrutin.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
      state.VentilationQuestions = {};
    });
    builder.addCase(publishScrutin.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
    });
    builder.addCase(forcerStatutScrutin.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
    });
    builder.addCase(fetchAllCampagnes.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
      state.fetchAllCampagnes = action.payload.result;
    });
    builder.addCase(fetchCampagneById.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
    });
    builder.addCase(fetchCampagneByScrutinId.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
    });
    builder.addCase(addCampagne.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
    });
    builder.addCase(addScrutin.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
    });
    builder.addCase(setTheme.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
    });
    builder.addCase(setOrdreScrutins.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
    });
    builder.addCase(demarrerPrisePresences.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
    });
    builder.addCase(arreterPrisePresences.fulfilled, (state, action) => {
      _.merge(state, action.payload.entities);
    });
    builder.addCase(updateScrutin.fulfilled, (state, action) => {
      state.Scrutins = {
        ...state.Scrutins,
        [action.payload.Id]: action.payload,
      };
      return state;
    });
    builder.addCase(updateQuestionsScrutin.fulfilled, (state, action) => {
      state.Scrutins = {
        ...state.Scrutins,
        [action.payload.Id]: action.payload,
      };
      return state;
    });
    builder.addCase(addCandidatReponse.fulfilled, (state, action) => {
      state.Scrutins = {
        ...state.Scrutins,
        [action.payload.Id]: action.payload,
      };
      return state;
    });

    builder.addCase(fetchVentilationScrutin.fulfilled, (state, action) => {
      state.VentilationQuestions[action.meta.arg.id] = action.payload;
      return state;
    });
    builder.addCase(fetchApiInfo.fulfilled, (state, action) => {
      state.ApiEnvironment = action.payload.ASPNETCORE_ENVIRONMENT;
      return state;
    });
  },
});

export const { scrutinModifie, campagneModifie } = adminCampagnesSlice.actions;

export const selectCampagnes = (state: AppState) => {
  const ids = state.admin.campagne.fetchAllCampagnes;
  return ids.map((id) => state.admin.campagne.Campagnes[id]);
};

export const selectCampagne = (id: number) => (state: AppState) =>
  state.admin.campagne.Campagnes[id];

export const selectScrutin = (id: number) => (state: AppState) =>
  state.admin.campagne.Scrutins[id];

export const selectCampagneScrutins = (campagneId: number) => (
  state: AppState
) => {
  const scrutins = Object.values(state.admin.campagne.Scrutins).filter(
    (c) => c.CampagneId === campagneId
  );
  scrutins.sort((a, b) => a.Ordre - b.Ordre);
  return scrutins;
};

// TODO: Move?
export const selectApiEnvironment = (state: AppState) =>
  state.admin.campagne.ApiEnvironment;

export const selectVentilation = (scrutinId: number) => (state: AppState) =>
  state.admin.campagne.VentilationQuestions[scrutinId];

export default adminCampagnesSlice.reducer;
