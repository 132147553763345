/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import api, { AddInscriptionParameters, AideParameters } from './api';

export const fetchCampagne = createAsyncThunk(
  `soutien/fetchCampagne`,
  async (parameters: { campagneUniqueId: string }) => {
    const response = await api.fetchCampagne(parameters.campagneUniqueId);
    return response;
  }
);

export const addInscription = createAsyncThunk(
  `soutien/Inscription`,
  async (parameters: AddInscriptionParameters) => {
    const response = await api.addInscription(parameters);
    return response;
  }
);

export const aide = createAsyncThunk(
  `soutien/Aide`,
  async (parameters: AideParameters) => {
    const response = await api.aide(parameters);
    return response;
  }
);

export const recuperer = createAsyncThunk(
  `soutien/recuperer`,
  async (parameters: {
    campagneId: number;
    courriel: string;
    origin: string;
  }) => {
    const response = await api.recuperer(parameters);
    return response;
  }
);
