import { combineReducers } from 'redux';
import adminCampagnesReducer from 'moduleAdmin/features/adminCampagnes/slice';
import accountReducer from 'moduleAdmin/features/account/slice';
import candidatsReducer from 'moduleAdmin/features/candidats/slice';
import inscriptionReducer from 'moduleAdmin/features/inscription/slice';
import emailReducer from 'moduleAdmin/features/email/slice';

const rootReducer = combineReducers({
  campagne: adminCampagnesReducer,
  account: accountReducer,
  candidats: candidatsReducer,
  inscription: inscriptionReducer,
  email: emailReducer,
});

export default rootReducer;
