import api, { Api } from 'backend/Api';
import { ThemeCampagne } from 'models/adminModel';

const apiRoute = `theme`;

const fetchAllTheme = (token: string) => api
    .get<ThemeCampagne[]>(apiRoute, Api.bearerAuth(token))
    .then((response) => response.data);

const fetchThemeById = (id: number, token: string) => api
    .get<ThemeCampagne>(`${apiRoute}/${id}`, Api.bearerAuth(token))
    .then((response) => response.data);

export default {
  fetchAllTheme,
  fetchThemeById,
};
