// On réassigne state mais c'est prévu par redux-toolkit
/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchCampagne } from 'features/home/actions';
import { Bulletin, Scrutin } from 'models/voteModel';
import { AppState } from 'store';
import { sendVotes } from './actions';

const sliceName = 'vote';

export type ChoixReponse = { [questionId: number]: number[] };

export interface VoteSlice {
  choix: ChoixReponse;
  dirty: boolean;
  scrutin?: Scrutin;
  bulletin: Bulletin;
}

const initialBulletin: Bulletin = { BulletinScrutins: [] };

const initialState: VoteSlice = {
  choix: {},
  dirty: false,
  bulletin: initialBulletin,
};

export const voteSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    initierVote: (
      state,
      action: PayloadAction<{
        scrutin: Scrutin;
        bulletin?: Bulletin;
      }>
    ) => ({
      ...state,
      dirty: false,
      choix: {},
      scrutin: action.payload.scrutin,
      bulletin: action.payload.bulletin || initialBulletin,
    }),
    modifierChoixReponse: (
      state,
      action: PayloadAction<{
        questionId: number;
        reponsesIds: number[];
      }>
    ) => {
      state.dirty = true;
      state.choix[action.payload.questionId] = action.payload.reponsesIds;
      return state;
    },
    terminerVote: (state) => ({
      ...state,
      dirty: false,
      choix: [],
      scrutin: undefined,
    }),
    reprendreScrutin: (
      state,
      action: PayloadAction<{ scrutinUniqueId: string }>
    ) => {
      state.bulletin.BulletinScrutins = state.bulletin.BulletinScrutins.filter(
        (bs) => bs.ScrutinUniqueId !== action.payload.scrutinUniqueId
      );
      return state;
    },
    enleverMessageAvantVote: (state) => {
      if (state.scrutin) {
        state.scrutin.MessageAvantVote = undefined;
      }
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCampagne.fulfilled, (state, action) => {
      if (action.payload.ScrutinActif) {
        state.scrutin = action.payload.ScrutinActif;
      }
      state.bulletin = action.payload.Bulletin || initialBulletin;
      state.choix = {};
      state.dirty = false;
      return state;
    });
    builder.addCase(sendVotes.fulfilled, (state, action) => {
      state.dirty = false;
      state.bulletin = action.payload;
      return state;
    });
  },
});

export const {
  modifierChoixReponse,
  initierVote,
  terminerVote,
  reprendreScrutin,
  enleverMessageAvantVote,
} = voteSlice.actions;
export const voteSliceName = voteSlice.name;

export const selectScrutin = (state: AppState) => state.vote.scrutin;

export const selectBulletin = (state: AppState) => state.vote.bulletin;

export const selectDejaVote = (state: AppState) =>
  state.vote.bulletin.BulletinScrutins.map((bs) => bs.ScrutinUniqueId).includes(
    state.vote.scrutin?.UniqueId || ''
  );

export const selectAnyVote = (state: AppState) =>
  state.vote.bulletin.BulletinScrutins.length > 0;

export const selectChoix = (state: AppState) => state.vote.choix;

export const selectChoixPourQuestion = (questionId: number) => (
  state: AppState
) => state.vote.choix[questionId];

export default voteSlice.reducer;
