/* eslint-disable import/prefer-default-export */
import JwtDecode from 'jwt-decode';
import { Bulletin } from './models/voteModel';

export const checkDates = (
  debut: string,
  fin: string
): { premature: boolean; expired: boolean; active: boolean } => {
  const premature = new Date() < new Date(debut);
  const expired = new Date() > new Date(fin);
  const active = !premature && !expired;
  return { premature, expired, active };
};

export const checkDejaVote = (
  scrutinUniqueID: string,
  bulletin?: Bulletin
): boolean =>
  bulletin?.BulletinScrutins.some(
    (b) => b.ScrutinUniqueId === scrutinUniqueID
  ) || false;

export interface RenderProperty<TChildrenProperties, TElement = unknown> {
  (properties: TChildrenProperties): React.ReactElement<TElement>;
}

export const extractDataFromToken = (token: string) => {
  const content = JwtDecode(token) as {
    'campagne-id'?: string;
    scrutins?: string[];
    'unique-id': string;
  };
  return content;
};
